@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #f9f9f9;
  color: #333;
}

.App {
  max-width: 900px;
  margin: auto;
  padding: 20px;
}

.header, .footer {
  text-align: center;
  padding: 50px 20px;
  background: white;
  color: black;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.header h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.header p {
  font-size: 1.2rem;
  line-height: 1.6;
}

.section {
  margin: 40px 0;
}

.section h2 {
  font-size: 2rem;
  color: black;
  margin-bottom: 20px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}

.experience-item, .extracurricular-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.experience-item img, .extracurricular-item img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.experience-content, .extracurricular-content {
  flex: 1;
}

.experience-content h3, .extracurricular-content h3 {
  font-size: 1.5rem;
  color: #2575fc;
  margin-bottom: 10px;
}

.projects .project-item {
  margin-bottom: 20px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.projects .project-item h3 {
  font-size: 1.3rem;
  color: black;
  margin-bottom: 10px;
}

ul {
  margin: 0;
  padding-left: 20px;
}

ul li {
  margin: 10px 0;
  line-height: 1.6;
}

.footer {
  margin-top: 50px;
  font-size: 1rem;
}

.footer a {
  color: black;
  font-weight: bold;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

html {
  scroll-behavior: smooth;
}

.card {
  margin-bottom: 20px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card h3 {
  font-size: 1.3rem;
  color: #2575fc;
  margin-bottom: 10px;
}

.card ul {
  margin: 0;
  padding-left: 20px;
  list-style-type: disc;
}

.card ul li {
  margin: 10px 0;
  line-height: 1.6;
}
